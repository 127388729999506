import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Qui m'avisera?" />
    <h3 className='underline-title'>Qui m'avisera?</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Aucune de mes femmes n'a nom Mikal<br />
        Je ne m'appelle pas David biblique <br />
        Qui alors m'avisera du danger imminent?<br />
        Fais que je devienne comme David <br />
        Et que j'aie femmes comme Mikal<br />
        Celles qui avisent leurs époux du danger <br />
        Fais que je saute par une fenêtre <br />
        Et que je m'engage sur la route du Sud <br />
        Chemin de mon exil salvateur <br />
      </p>
      <p>
        Mon nom ne figure pas sur leur liste <br />
        Liste du "plan d'évacuation"<br />
        Je suis donc laissé à moi-même <br />
        À la merci des révoltés <br />
      </p>
      <p>
        Tu es plus costaud que le planificateur <br />
        Plus coriace que les hors-la-loi <br />
        Ils ne peuvent me toucher qu'avec ta permission<br />
        Je crois en ta puissance <br />
        Elle défie le futurologue <br />
        Elle déçoit le proviseur <br />
        Même le führer dans son bunker <br />
      </p>
      <p>
        Je crois en toi<br />
        Ils disent que je suis fataliste <br />
        Ils se trompent comme des civilisés <br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
